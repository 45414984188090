<template>
  <multiselect
    v-model="value"
    label="name"
    track-by="id"
    open-direction="bottom"
    noResult="''"
    noOptions="''"
    :placeholder="placeholder"
    :options="filters"
    :multiple="false"
    :searchable="true"
    :loading="isLoading"
    :internal-search="false"
    :clear-on-select="true"
    :close-on-select="true"
    :show-no-results="false"
    :hide-selected="false"
    @search-change="asyncFind"
    @select="selectFilter"
  />
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import { abstractField } from 'vue-form-generator'
  import {APIService} from "@/services/api";

  export default {
    mixins: [abstractField],
    components: {
      Multiselect,
    },
    data () {
      return {
        label: '',
        placeholder: '',
        filter: null,
        filters: [],
        existingFiltersIds: [],
        isLoading: false
      }
    },
    async beforeMount() {
      this.label = this.schema.label;
      this.placeholder = this.schema.placeholder;
      this.existingFiltersIds = this.model.tagItem.filterRelations.map(fr => fr.filter.id);
      await this.asyncFind();
    },
    methods: {
      async asyncFind (query) {
        let queryString = ``;
        if (query) {
          queryString = `&filter=name||cont||${query}`
        }
        this.isLoading = true;
        try {
          const excludeIds = this.existingFiltersIds.join(',');
          const filters = await APIService.get(`filter?filter=id||$notin||${excludeIds}${queryString}`);
          this.filters = filters;
        }
        catch(e) {
          console.error(e);
        } finally {
          this.isLoading = false
        }
      },
      selectFilter(selectedOption) {
        this.value = selectedOption
      },
    }
  }
</script>

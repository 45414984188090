import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "filterSelect",
      label: "name",
      model: "filter",
      required: true,
      placeholder: i18n.t('selectFilter'),
      max: 255,
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("nameRequired"),
        }),
      ]
    },
  ]
};

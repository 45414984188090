<template>
  <div v-if="form.tagItem">
    <crud-form
      :schema="schema"
      :path="'tag-item-filters'"
      :model="form"
      :form-title="$t('addFilter')"
      :redirect-route="'TagItemsFiltersList'"
    />
  </div>
</template>

<script>
  import Vue from "vue";
  import CrudForm from '../../components/CrudForm';
  import { schema } from './schema/tag-item-filter'
  import fieldFilterSelect from "./components/fieldFilterSelect";
  import { APIService } from '../../services/api';

  Vue.component('fieldFilterSelect', fieldFilterSelect);

  export default {
    name: 'AddTagItemFilter',
    props: ['tagItemId'],
    components: {
      CrudForm
    },
    data: () => {
      return {
        schema,
        form: {
          filter: null,
          tagItem: null,
        },
      }
    },
    async beforeMount() {
      try {
        this.form.tagItem = await APIService.get(`tag-item/${this.tagItemId}?join=filterRelations&join=filterRelations.filter`);
      } catch(e) {
        console.error(e);
      }
    }
  }
</script>
